import applicatoinStyles from '/stylesheets/application.scss?inline';

let template = document.createElement('template');
template.innerHTML = /*html*/ `
  <style>
    ${applicatoinStyles}

    :host {
      display: flex;
      background-color: var(--gg-app-background);
      padding: .5rem .5rem 1rem .5rem;
      box-shadow: .5rem .5rem .5rem .5rem rgba(0,0,0,.25) !important;
      position: relative;
    }

    primary-actions {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    a:active > i {
      color: var(--gg-app-primary-dark)!important;
    }

    primary-actions .primary-action {
      display: flex;
      flex-direction: column;
      text-align: center;
      max-width: 20%;
      min-width: 18%;
      text-decoration: none;
      color: var(--gg-app-label);
    }

    primary-actions .primary-action.active action-label{
      font-weight: 600;
    }

    primary-actions .primary-action.active i{
      color: var(--gg-app-primary-bright);
    }

    primary-actions .primary-action.active  .fa-triangle-exclamation{
      color: var(--gg-app-primary-bright)!important;
    }

    primary-actions .primary-action i {
      font-size: 2rem;
      padding-bottom: 0.25rem;
      color: var(--gg-app-primary);
    }

    primary-actions .primary-action action-label {
      font-size: 0.75rem;
    }

  </style>

  <primary-actions>
    <a href="/city-hall.html" class="primary-action">
      <i class="fa-solid fa-building-columns" aria-hidden="true"></i>
      <action-label>
        Open Gov
      </action-label>
    </a>

    <a href="/services.html" class="primary-action">
      <i class="fa-kit fa-city-emblem" aria-hidden="true"></i>
      <action-label>
        Services
      </action-label>
    </a>

    <a href="/report-issue.html" class="primary-action d-flex align-items-center">

      <div class="fa-stack" style="height: 2.25rem;">
        <i class="fa-solid fa-triangle-exclamation fa-stack-2x" aria-hidden="true" style="font-size: 2rem; color: #0073BD; padding-bottom: 0;"></i>
        <i class="fa-solid fa-circle fa-stack-1x" style="color: #1A3C6B; font-size: 1rem; text-align:right; padding-top:.6rem;"></i>
        <i class="fa-solid fa-circle-plus fa-stack-1x" style="color: #FFB700; font-size: 1rem; text-align:right; padding-top:.6rem;"></i>
      </div>
      <action-label>
        Report Issue
      </action-label>
    </a>

    <a href="/residents.html" class="primary-action" aria-label="My Garden Grove">
      <i class="fa-solid fa-location-dot" aria-hidden="true"></i>
      <action-label>
        MyGG
      </action-label>
    </a>

    <a href="/news.html" class="primary-action">
      <i class="fa-solid fa-newspaper" aria-hidden="true"></i>
      <action-label>
        News
      </action-label>
    </a>
  </primary-actions>
`;

customElements.define('bottom-nav', class extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({
      mode: 'open'
    });
    this.shadowRoot.appendChild(template.content.cloneNode(true));

  }

  connectedCallback() {
    let anchors = this.shadowRoot.querySelectorAll('a');
    var current = location.pathname.split('/')[1];
    
    if (current !== '') {
      anchors.forEach((l) => {
        if (l.getAttribute('href').indexOf(current) !== -1) {
          l.classList.add('active');
          l.setAttribute('aria-current','page');
        }
      });
    }
  }
});
