import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { get, set } from 'idb-keyval';

export default class {
  firebaseConfig = {
    apiKey: "AIzaSyAqwcFPilYLIrVIu1-BprkC08hOAhWQikg",
    authDomain: "ggapp-d2bae.firebaseapp.com",
    projectId: "ggapp-d2bae",
    storageBucket: "ggapp-d2bae.appspot.com",
    messagingSenderId: "614262062561",
    appId: "1:614262062561:web:4def07426bd52832e8a6a0"
  };

  constructor() {
    this.app = initializeApp(this.firebaseConfig);
  }

  // Registers service worker and request permission and listen for message event
  initialize(nodeEventHandler) {
    this._nodeEventHandler = nodeEventHandler;

    // If we're in iOS land, try to receive push notification and fcm token from the native app there
    window.addEventListener('ios-push-notification', this._onAppleMessage.bind(this));
    window.addEventListener('ios-fcm-token', this._onFcmToken.bind(this));

    const messaging = getMessaging(this.app);

    if ("serviceWorker" in navigator) {
      // Register SW after window has loaded
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register(
            import.meta.env.MODE === 'production' ? '/sw.js' : '/dev-sw.js?dev-sw',
            { type: import.meta.env.MODE === 'production' ? 'classic' : 'module' }
          )
          .then((registration) => {
            getToken(messaging, {
              vapidKey: 'BBhX-HkAQM3ZNds7Me9xXirH-SnV2qpvVnXgmmUbnuhiHzhz3vPlzqy2tKKisJM6ywSZvjqGlF8fdraKbOR6Ohs',
              serviceWorkerRegistration: registration
            })
              .then(this._onFcmToken.bind(this))
              .then((currentToken) => {
                // We have approval to send notification, trigger event in case anyone cares
                window.dispatchEvent(new CustomEvent('push-permission-request', { detail: { status: 'Approved', token: currentToken } }));

                // Set up foreground listener for messages
                onMessage(messaging, this._onMessage.bind(this));
              })
              .catch((err) => {
                // either user rejected notification permission or we're in iOS land
                window.dispatchEvent(new CustomEvent('push-permission-request', { detail: { status: 'Rejected or iOS' } }));
              });
          })
          .catch((err) => {
            console.error("Service worker registration failed.", err);
          });
      });
    }
  }

  /**
   * Get all past notifications.
   * @param {Object} opts filter, sortBy, limit. Uses native Array filter, sort, and slice.
   * @returns {Promise} JSON
   */
  getPastNotifications(opts = {}) {
    opts = Object.assign({
      sortBy: (a, b) => b.sentAt - a.sentAt,
      filter: (a) => /^all/.test(a.topic),
      limit: 10
    }, opts);

    return fetch('/api/getNotifications')
      .then((res) => res.json())
      .then((data) => {
        return data
          .filter(opts.filter)
          .sort(opts.sortBy)
          .slice(0, opts.limit);
      });
  }

  /**
   * A best guess at knowing whether notifications permission was granted
   * @returns {Promise}
   */
  notificationsAllowed() {
    // if fcmToken is set then we probably have permission for notification
    return get('fcmToken');
  }

  _onMessage(payload) {
    if (payload.notification) {
      const notificationPayload = {
        "notification": {
          "title": payload.notification.title,
          "body": payload.notification.body
        },
        "link": (payload.data) ? payload.data.link : ''
      };

      this._nodeEventHandler.dispatchEvent(new CustomEvent('push-notification', { detail: notificationPayload }));
    }
  }

  _onAppleMessage(event) {
    if (event.detail && event.detail.aps && event.detail.aps.alert) {
      const payload = event.detail;
      const translatedPayload = {
        "notification": {
          "title": payload.aps.alert.title,
          "body": payload.aps.alert.body
        },
        "data": {
          "link": payload.link
        }
      };

      this._onMessage(translatedPayload);
    }
  }

  _onFcmToken(message) {
    // From iOS this comes as a custom event
    // Otherwise, the message itself is the token
    let newToken, device;
    if (message.detail) {
      newToken = message.detail;
      device = 'ios';
    } else {
      newToken = message;
      device = 'pwa';
    }

    let fcmToken = {
      token: newToken,
      updatedAt: (new Date()).valueOf(),
      device: device,
      topics: ['allv1']
    };

    // Always update with new token and timestmap
    set('fcmToken', fcmToken)
      .catch((err) => console.error('Unable to save FCM Token to local storage', err));

    // Sends this token to server
    // Server side piece will handle checking if a token already exists
    fetch('/api/tokenUpdateSubscribe', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(fcmToken)
    }).catch((err) => console.error('Unable to send FCM Token to server', err));

    return newToken;
  }
}
