import { Modal } from '~bootstrap/dist/js/bootstrap.esm.min.js'
import applicationStyles from '/stylesheets/application.scss?inline';

let template = document.createElement('template');

template.innerHTML = /*html*/ `
  <style>
    ${applicationStyles}
  </style>

  <div class="modal" id="gg-modal" tabindex="-1" aria-labelledby="notification-title" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="notification-title" id="notification-title"></h2>
          <button type="button" class="btn-close pt-0" data-bs-dismiss="modal" aria-label="Close" id="close-modal" aria-label="Close notification"><i class="fa-solid fa-circle-xmark" aria-hidden="true"></i></button>
        </div>
        <div class="modal-body"></div>
      </div>
    </div>
  </div>
  `;

customElements.define('notification-modal', class extends HTMLElement {
  constructor() {
    super();

    const shadow = this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
  }

  connectedCallback() {
    this._modalDOM = this.shadowRoot.getElementById('gg-modal');
    let closeModal = this.shadowRoot.getElementById('close-modal');

    this._notificationModal = new Modal(this._modalDOM);

    let modalTitle = this.shadowRoot.getElementById('notification-title');
    let modalBody = this.shadowRoot.querySelector('.modal-body');
    let modalBodyText = document.createElement('p');
    let optionalLink = this.getAttribute('link');
    modalTitle.innerHTML = this.getAttribute('title');
    modalBodyText.innerHTML = this.getAttribute('body');

    modalBody.appendChild(modalBodyText);
    
    if(optionalLink.length > 0){
      let modalLink = document.createElement('a');
      modalLink.setAttribute('href', this.getAttribute('link'));
      modalLink.classList.add('btn');
      modalLink.classList.add('call-to-action');
      modalLink.innerHTML = "Read more";
      modalBody.appendChild(modalLink);
    }
    
    this._notificationModal.show();
    closeModal.addEventListener('click', () => this.remove());
  }

  disconnectedCallback() {
    this._notificationModal.hide();
    this._notificationModal.dispose();
    this._modalDOM.remove();
  }
});
